<template>
    <div>
        <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content" style="color: #2E8B57;">
            <span class="close" @click="closeModal">&times;</span>
            <h3 style="font-weight: bold;">Changes saved successfully!</h3>
        </div>
    </div>
        <div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-5">
                    <label class="form-select-label kx-label-secondary" for="State">SVF Center</label>
                    <select class="form-select" aria-label="State" v-model="svf_center">
                        <option v-for="option in opd_location_options" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div>
                                <label for="svf_booking_date" class="form-label kx-label-secondary">
                                    SVF Booking Date</label>
                                <VueDatePicker id="svf_booking_date" v-model="svf_booking_date"
                                    format="dd/MM/yyyy H:mm"></VueDatePicker>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div>
                                <label for="svf_done_date" class="form-label kx-label-secondary">
                                    SVF Done Date</label>
                                <VueDatePicker id="svf_done_date" v-model="svf_done_date"
                                    format="dd/MM/yyyy H:mm"></VueDatePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-primary" @click="saveChanges" style="background-color: #007bff;"
                    @mouseover="changeColor" @mouseleave="resetColor">Save</button>
                </div>
            </div>

        </div>
    </div>
</template>
    
<script>
import axios from "axios";
export default {
    name: "SvfTab",
    props: {
        msg: String,
    },
    mounted() {
        this.get_SVF_Data();
        this.get_opd_list();
    },
    data() {
        return {
            opd_location_options: [],
            name: "",
            svf_center:"",
            svf_booking_date: null,
            svf_done_date:null,
            showModal: false,
        };
    },
    methods: {

        get_opd_list: async function () {
            await axios
                .get("knee_lead.api.get_center_list")
                .then((response) => {
                    var res_data = response.data.data[0];
                    for (let x in res_data) {
                        this.opd_location_options.push(res_data[x].name);
                    }
                })
                .catch((error) => {});
        },

        get_SVF_Data: async function () {
            await axios
                .get("knee_lead.api.get_single_knee_lead", {
                    params: {
                        mobile: this.$route.params.id,
                    },
                })
                .then((response) => {
                    for (let x in response.data.data) {
                        this.name = response.data.data[x].name;
                        this.svf_booking_date =
                            response.data.data[x].svf_booking_date;
                        this.svf_done_date =
                            response.data.data[x].svf_done_date;

                            this.svf_center =
                            response.data.data[x].svf_center;
                    }
                })
                .catch((error) => { });
        },

        formatDate(date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const hours = String(formattedDate.getHours()).padStart(2, '0');
            const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
            const seconds = String(formattedDate.getSeconds()).padStart(2, '0');
            const milliseconds = String(formattedDate.getMilliseconds()).padStart(3, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        },

        saveChanges: async function () {
            try {
                let updatedFields = {
                    name: this.$route.params.id,
                    svf_center: this.svf_center,
                    svf_booking_date: null,
                    svf_done_date: null
                };

                if (this.svf_booking_date) {
                    const formattedDate = new Date(this.svf_booking_date).toISOString();
                    updatedFields.svf_booking_date = this.formatDate(formattedDate);
                }
                if (this.svf_done_date) {
                    const formattedDate = new Date(this.svf_done_date).toISOString();
                    updatedFields.svf_done_date = this.formatDate(formattedDate);
                }
                await this.update_fields(updatedFields);
                this.showModal = true;
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            } catch (error) {
                console.error('Error saving changes:', error);
            }
        },

        update_fields: async function (updatedFields) {
            try {
                const response = await axios.post("knee_lead.knee_lead_details.update_knee_lead_svf_tab", updatedFields);
                this.get_SVF_Data();
            } catch (error) {
                console.error('Error updating fields:', error);
                throw error;
            }
        },

        closeModal() {
            this.showModal = false;
        },
      
    },

};
</script>
    
<style scoped lang="scss">
.modal {
    /* Styles for modal overlay */
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    /* Styles for modal content */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;
    width: 100%;
    position: relative;
    /* Ensure the close icon stays in the modal */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}
</style>
    