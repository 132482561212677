<template>
    <div>
        <div class="card kx-card">
            <div class="row">
                <div class="card kx-card">
                    <div v-if="loading">loading ..</div>
                    <div class="col-sm-12" v-else>
                        <div class="row">
                            <div class="col-sm-6" v-if="xrayTypes.length > 0">
                                <label for="formFile" class="form-label kx-label-secondary">Filter</label>
                                <br />

                                <div v-for="(item, index) in xrayTypes" :key="index"
                                    class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="filterRadioOther"
                                        :id="'filterRadio' + index" v-model="search" :value="item.xray_type" />
                                    <label class="form-check-label" :for="'filterRadio' + index">
                                        {{ item.xray_type }}
                                    </label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="filterRadioOther"
                                        id="filterRadioAll" v-model="search" value="All" />
                                    <label class="form-check-label" for="filterRadioAll">All</label>
                                </div>
                            </div>


                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col">
                                        <label for="formFile" class="form-label kx-label-secondary">Upload {{
                                            upload_type }}</label>
                                        <input class="form-control" type="file" id="upload_xray_image"
                                            @change="handle_xray_upload" accept="image/*, application/pdf"
                                            placeholder="Xray" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col kx-label-secondary">
                                        <div v-for="(xray, index) in xrayTypes" :key="index"
                                            class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                :id="'Radio' + xray.xray_type.replace(/\s+/g, '')" v-model="upload_type"
                                                :value="xray.xray_type" />
                                            <label class="form-check-label"
                                                :for="'Radio' + xray.xray_type.replace(/\s+/g, '')">
                                                {{ xray.xray_type }}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card kx-card">
                <div class="row">
                    <nav v-if="totalPages > 1">
                        <button class="pagination-button" :disabled="currentPage === 1" @click="currentPage--">
                            Prev
                        </button>
                        <span>{{ currentPage }} of {{ totalPages }}</span>
                        <button class="pagination-button" :disabled="currentPage === totalPages" @click="currentPage++">
                            Next
                        </button>
                    </nav>

                    <div class="col-sm-3" v-for="(item, index) in filteredxrayItems" :key="item.name">
                        <div class="card mt-2">
                            <a @click="fnShowXrayModel(item.xray)">
                                <img class="card-img-top"
                                    v-bind:src="'data:' + detectMimeType(item.xray) + ';base64,' + item.xray"
                                    width="200" height="200" :alt="item.type"
                                    v-if="['image/png', 'image/jpg', 'image/gif'].includes(detectMimeType(item.xray))" />
                                <img class="card-img-top" src="../../assets/img/pdf-file.svg" width="200" height="200"
                                    :alt="item.type" v-else />
                            </a>

                            <div class="card-body">
                                <!-- Inline Editing: Clicking on Type Replaces it with Select -->
                                <h5 class="card-title">
                                    <span v-if="!editingType[item.name]" @click="editingType[item.name] = true">
                                        {{ item.type }}
                                    </span>

                                    <!-- Dropdown Appears When Editing -->
                                    <select v-else v-model="item.type" @change="updateXrayType(item.name, item.type)"
                                        @blur="editingType[item.name] = false" class="select_field">
                                        <option v-for="xray in xrayTypes" :key="xray.xray_type" :value="xray.xray_type">
                                            {{ xray.xray_type }}
                                        </option>
                                    </select>
                                </h5>

                                <p class="card-text card-date">
                                    {{ format_date_time(item.date) }}
                                </p>

                                <button class="btn btn-info" @click="fnShowXrayModel(item.xray)">
                                    Open
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <ShowXray @add="ShowXrayModel = true" @close="ShowXrayModel = false" :show="ShowXrayModel" :xrayId="xrayId"
        msg="TIkam" />
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";
// import ModalSuccess from "@/components/modals/ModalSuccess.vue";
import ShowXray from "@/components/modals/ShowXray.vue";

// ShowXrayModal;

export default {
    name: "FilesTab",
    components: {
        pdf,
        ShowXray,
    },
    props: {
        msg: String,
    },
    mounted() {
        this.get_xray_record();
        this.fetch_xray_base64();
        this.fetchXrayTypes();
    },
    data() {
        return {
            loading: false,
            currentPage: 1,
            perPage: 8,
            xray_records: [],
            xrayTypes: [],
            search: "All",
            upload_type: "Xray",
            file_type: "",
            xrayId: "",
            ShowXrayModel: false,
            editingType: {},
            // handle_xray_upload: '',
        };
    },
    computed: {
        filteredxrayItems() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;

            if (this.search) {
                if (this.search == "All") {
                    return this.xray_records.slice(startIndex, endIndex);
                }

                return this.xray_records
                    .filter((item) => item.type.toLowerCase() === this.search.toLowerCase())
                    .slice(startIndex, endIndex);
            } else {
                return this.xray_records.slice(startIndex, endIndex);
            }
        },

        paginatedDatadirectxray() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return this.xray_records.slice(startIndex, endIndex);
        },

        totalPages() {
            if (this.search === "All") {
                return Math.ceil(this.xray_records.length / this.perPage);
            } else {
                const filteredData = this.xray_records.filter(
                    (item) => item.type.toLowerCase() === this.search.toLowerCase()
                );
                return Math.ceil(filteredData.length / this.perPage);
            }
        },
    },

    methods: {

        async updateXrayType(name, xray_type) {
            try {
                const payload = {
                    name: name,
                    xray_type: xray_type
                };

                await axios.post("knee_lead.api.update_direct_xray_type", payload);
                const item = this.xray_records.find(record => record.name === name);
                if (item) {
                    item.type = xray_type;
                }

                this.$set(this.editingType, name, false);
            } catch (error) {
                console.error("Error updating x-ray type:", error);
            }
        },

        async fetchXrayTypes() {
            try {
                const response = await axios.get("knee_lead.api.get_direct_xray_type", {
                    params: { knee_lead_id: this.$route.params.id },
                });

                if (response.data && response.data.data) {
                    this.xrayTypes = response.data.data;
                } else {
                    this.xrayTypes = [];
                }
            } catch (error) {
                console.error("Error fetching x-ray types:", error);
            }
        },

        fnShowXrayModel: function (e) {
            this.xrayId = e;
            this.ShowXrayModel = !this.ShowXrayModel;
        },
        getFileExtension: function (filename) {
            var file_type = filename.slice(
                ((filename.lastIndexOf(".") - 1) >>> 0) + 2
            );
        },

        handle_xray_upload: function (e) {
            const selectedImage = e.target.files[0];
            if (selectedImage["type"] === "image/jpeg") {
                this.file_type = "jpeg";
            }
            if (selectedImage["type"] === "image/jpg") {
                this.file_type = "jpg";
            }

            if (selectedImage["type"] === "image/png") {
                this.file_type = "png";
            }
            if (selectedImage["type"] === "application/pdf") {
                this.file_type = "pdf";
            }

            this.createBase64Imagexray(selectedImage);
        },

        createBase64Imagexray: function (fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                let base_64_file = e.target.result.split(",");
                this.upload_xray_image = base_64_file[1];
                this.upload_xray();
            };
            reader.readAsDataURL(fileObject);
        },
        upload_xray: async function () {
            function checkZero(data) {
                if (data.length == 1) {
                    data = "0" + data;
                }
                return data;
            }
            var today = new Date();
            var day = today.getDate() + "";
            var month = today.getMonth() + 1 + "";
            var year = today.getFullYear() + "";
            var hour = today.getHours() + "";
            var minutes = today.getMinutes() + "";
            var seconds = today.getSeconds() + "";

            day = checkZero(day);
            month = checkZero(month);
            year = checkZero(year);
            var date_final = year + "-" + month + "-" + day;

            const formdata = {
                mobile: this.$route.params.id,
                date: date_final,
                type: this.upload_type,
                mode: "Direct",
                filename: this.$route.params.id + this.patient_full_name + "." + this.file_type,
                filedata: this.upload_xray_image,
            };
            this.loading = !false;

            await axios
                .post("knee_lead.api.add_direct_xray", formdata)
                .then((response) => {
                    this.get_xray_record();
                    this.loading = false;
                    this.upload_xray_image = "";

                    document.getElementById("upload_xray_image").value = "";
                })
                .catch((error) => { });
        },
        fetch_xray_base64: function () {
            axios
                .get("knee_lead.api.get_direct_xray_base64", {
                    params: {
                        knee_lead_id: this.$route.params.id,
                    },
                })
                .then((response) => {
                    this.xray_records = response.data.data;
                })
                .catch((error) => { });
        },

        detectMimeType: function (b64) {
            var signatures = {
                JVBERi0: "application/pdf",
                R0lGODdh: "image/gif",
                R0lGODlh: "image/gif",
                iVBORw0KGgo: "image/png",
                "/9j/": "image/jpg",
            };

            for (var s in signatures) {
                if (b64.indexOf(s) === 0) {
                    return signatures[s];
                    // return "data:" + signatures[s] + ";base64," + b64;
                    // data:application/pdf;base64,
                }
            }
        },

        get_xray_record: async function () {
            await axios
                .get("knee_lead.api.get_direct_xray", {
                    params: {
                        knee_lead_id: this.$route.params.id,
                    },
                })
                .then((response) => { })
                .catch((error) => { });
        },
        format_date_time: function (e) {
            if (e) {
                var date_time = e.split(" ");
                var date = date_time[0];
                var time = date_time[1];
                var final_date = date.split("-");
                if (time) {
                    var final_date_time =
                        final_date[2] +
                        "-" +
                        final_date[1] +
                        "-" +
                        final_date[0] +
                        " " +
                        time;
                }
                var final_date_time =
                    final_date[2] + "-" + final_date[1] + "-" + final_date[0];

                return final_date_time;
            }
            return;
        },
    },
};
</script>

<style scoped lang="scss">
.select_field {
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 5px;
    border: none;
}
</style>