<template>
    <div class="modal-mask" v-if="show">
        <div class="modal-container rounded">
            <div class="modal-header">
                <h3 class="modal-title fs-5" id="exampleModalLabel">
                    Send WhatsApp to <strong> {{ Knee_lead }} </strong>
                </h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal-mask" aria-label="Close"
                    @click="$emit('close')"></button>
            </div>

            <div class="modal-body">
                <div class="row mt-2">
                    <div class="col-sm-6">
                        <label class="form-check-label" for="Group">Template Category</label>
                        <Multiselect id="Group" v-model="whatsapp_template" :options="whatsapp_templates"
                            :searchable="true">
                        </Multiselect>
                    </div>

                    <div class="col-sm-6">
                        <label class="form-check-label" for="users">Language</label>
                        <Multiselect id="users" v-model="selected_language" :options="options" :searchable="true">
                        </Multiselect>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <button class="btn btn-primary btn-sm" @click="checkTemplate">
                            <i class="fas fa-check"></i> Check Template
                        </button>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-6">

                        <div v-if="templateResponse.length > 0">
                            <label>Select Template</label>
                            <Multiselect v-model="selected_template" :options="templateResponse" :searchable="true"
                                label="label" @update:modelValue="handleTemplateSelection" />

                        </div>
                    </div>

                    <div class="col-6" v-if="showCenterSelect" style="margin-top: -20px;">

                        <label class="form-select-label kx-label-secondary" for="State">Select Center</label>
                            <select class="form-select" aria-label="State" v-model="selected_center">
                                <option v-for="option in opd_location_options" :value="option">
                                    {{ option }}
                                </option>
                            </select>

                    </div>

                    <!-- Message if No Data Found -->
                    <div v-else class="no-data-message">
                        {{ messageData }}
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-sm btn-success mt-2" @click="
                    send_template();
                $emit('close');
                ">
                    <i class="fas fa-share"></i> Send Template
                </button>
            </div>
        </div>
    </div>

    <ModalSuccess @success="ShowSuccessModel = true" @close="ShowSuccessModel = false" :show="ShowSuccessModel"
        msg="WhatsApp Sent Successfully" />

    <ModelFail @success="ShowFailedModel = true" @close="ShowFailedModel = false" :show="ShowFailedModel"
        msg="WhatsApp Failed to Send" />
</template>

<script>
import axios from "axios";
import Multiselect from "@vueform/multiselect";
import ModalSuccess from "@/components/modals/ModalSuccess.vue";
import ModelFail from "@/components/modals/ModelFail.vue";
export default {
    name: "ModalTemplateShare",
    components: { Multiselect, ModalSuccess, ModelFail },
    props: {
        msg: String,
        show: false,
        exercise_options: [],

        Knee_lead: "",
    },
    mounted() {
        this.get_templates();
        this.get_languages();
        this.get_opd_list();
        this.checkTemplate();
    },
    data() {
        return {
            ShowSuccessModel: false,
            ShowFailedModel: false,
            whatsapp_templates: [],
            opd_location_options: [],
            selected_centers: [],
            selected_center: "",
            showCenterSelect: false,
            selected_template: null,
            selectedtherapyplan: [],
            name: "",
            mobile: "",
            exercise_req: "",
            selected_language: "",
            options: [],
            whatsapp_template: "",
            roles: [],
            whatsapp_no: "",
            doctype: "Knee Lead",
            user: this.selected,
            read: "0",
            write: "0",
            submit: "0",
            share: "0",
            everyone: "0",
            notify: "0",
            xray_records: [],
            xray_files: [],
            therapy_plan_template: [],
            lead_basic_details: [],
            templateResponse: [],
            messageData: ""
        };
    },
    methods: {

        get_opd_list: async function () {
            await axios
                .get("knee_lead.api.get_center_list")
                .then((response) => {
                    var res_data = response.data.data[0];
                    for (let x in res_data) {
                        this.opd_location_options.push(res_data[x].name);
                    }
                })
                .catch((error) => { });
        },

        handleTemplateSelection(selectedValue) {
    const selectedTemplate = this.templateResponse.find(
        (template) => template.value === selectedValue
    );

    if (selectedTemplate) {
        this.showCenterSelect = selectedTemplate.for_doctype?.includes("Center") ?? false;
    } else {
        this.showCenterSelect = false;
    }
},


        async checkTemplate() {
            try {
                const response = await axios.post(
                    "knee_lead.send_template_via_wa_flow.get_templates_to_send",
                    {
                        template_category: this.whatsapp_template,
                        language: this.selected_language,
                    }
                );

                if (response.data.status_code === 200 && response.data.data.length > 0) {
                    this.templateResponse = response.data.data.map(item => ({
                        value: item.whatsapp_template,
                        label: item.whatsapp_template,
                        name: item.name,
                        for_doctype: item.for_doctype
                    }));
                } else {
                    this.templateResponse = [];
                    this.messageData = "No templates available for the selected options.";
                }
            } catch (error) {
                console.error("Error checking template:", error);
                this.templateResponse = [];
                this.messageData = "Failed to fetch templates. Please try again.";
            }
        },


        async get_templates() {
            try {
                const response = await axios.get(
                    "knee_lead.send_template_via_wa_flow.get_template_category"
                );

                if (response.data) {
                    this.whatsapp_templates = response.data.data.map((template) => ({
                        value: template.name,
                        label: this.split_characters(template.name),
                    }));
                }
            } catch (error) {
                console.error("Error fetching templates:", error);
            }
        },

        async get_languages() {
            try {
                const response = await axios.get(
                    "knee_lead.send_template_via_wa_flow.get_languages"
                );
                if (response.data) {
                    this.options = response.data.data.map((lang) => ({
                        value: lang.name,
                        label: lang.custom_language_name_sahaj,
                    }));
                }
            } catch (error) {
                console.error("Error fetching languages:", error);
            }
        },


        async send_template() {
        try {
            if (!this.selected_template) {
                alert("Please select a template before sending.");
                return;
            }

            const payload = {
                template: this.selected_template,
                knee_lead: this.Knee_lead,
                center: this.showCenterSelect ? this.selected_center : ""
            };

            console.log("Payload to send:", payload);

            const response = await axios.post(
                "knee_lead.send_template_via_wa_flow.send_template_via_wa_flow",
                payload
            );

            if (response.data && response.data.status_code === 200) {
                this.ShowSuccessModel = true;
                setTimeout(() => {
                    this.ShowSuccessModel = false;
                }, 2000);
            } else {
                this.ShowFailedModel = true;
                setTimeout(() => {
                    this.ShowFailedModel = false;
                }, 2000);
            }
        } catch (error) {
            console.error("Error sending template:", error);
            this.ShowFailedModel = true;
        }
    },

        split_characters(str) {
            return str
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        },



        split_characters: function (str) {
            var i,
                frags = str.split("_");
            for (i = 0; i < frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(" ");
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    inset: 0;
    background: rgba(8, 0, 8, 0.4);
    z-index: 2;
    display: grid;
    place-items: center;
}

.modal-container {
    background: white;
    padding: 1rem;
    width: 80vw;
    max-width: 50%;
}

.modal {
    display: block !important;
    /* I added this to see the modal, you don't need this */
}

/* Important part */

.modal-dialog {
    overflow-y: initial !important;
}

.modal-body {
    height: 80vh;
    overflow-y: auto;
}

.no-data-message {
    text-align: center;
    font-weight: bold;
    color: red;
    margin-top: 20px;
}
</style>